import { h, Fragment, Component } from 'preact';

const Music = () => {
	return(
		<Fragment>
		<iframe width="80%" max-width="250px" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/789661915&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/yungradley" title="Radley" target="_blank" style="color: #cccccc; text-decoration: none;">Radley</a> · <a href="https://soundcloud.com/yungradley/oragami" title="Origami (Feat. Chris Billi)" target="_blank" style="color: #cccccc; text-decoration: none;">Origami (Feat. Chris Billi)</a></div>
		</Fragment>
	);
}
export default Music;
