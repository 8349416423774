import { h, Fragment, Component } from 'preact';
import {useState} from 'preact/hooks';

const Header = (props) => {
	
	return (
		<div class="header">
			<a href="/"><img src="damnagedgoods.png" class="logo" /></a>
			<div class="nav-links">
				<a href="/music">Music</a>
				<a href="/shop">Shop</a>
				<a href="/about">About</a>
			</div>
			<div class="header-checkout">
				<div class="cart-info">{props.cartItems.length}</div>
				<a href="/checkout"><img src="cart.png" /></a>
			</div>
		</div>
	);
}

export default Header;
