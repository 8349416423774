import { h, Fragment, Component } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import Product from './product';

const axios = require("axios");

const Products = (props) => {

	const [productStatus, setProductStatus] = useState("loading");
	const [productArray, setProductArray] = useState([]);
	const [errorMessage, setErrorMessage] = useState("something went wrong");

	/**/

	useEffect(() => {
		let isCancelled = false;
		const getProducts = async () => {
			try {
				if (!isCancelled) {
					let products = await axios.get("https://00dzzl369b.execute-api.us-east-1.amazonaws.com/getProducts");
					products = products.data;
					setProductStatus("got");
					setProductArray(products);
				}
			}
			catch (err) {
				if (!isCancelled) {
					setProductStatus("error");
					console.log(err);
					setErrorMessage(err.response.data.error.toString());
				}
			}
		}

		getProducts();

		return () => {
			isCancelled = true;
		};
	}, []);

	return (
		<div class="products">
			{
				(() => {
					if (productStatus == "loading") {
						return ("Loading products...");
					}
					else if (productStatus == "got") {
						let productDisplayArray = [];

						let cartItemPrices = props.cartItems.map(x => x.price);

						productDisplayArray = productArray.map(
							x => {
								if (!cartItemPrices.includes(x.price)) {
									return(
										<Product picked={false} addAction={props.addToCart} item={x} />
									);
								}
								else {
									return(
										<Product picked={true} addAction={props.addToCart} item={x} />
									);
								}
							}
						);
						return (productDisplayArray);
					}
					else{
						return(<span style="color: red;">{errorMessage}</span>);
					}
				})()
			}
		</div>
	);

}

export default Products;
