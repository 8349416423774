import { h, Fragment, Component } from 'preact';
import { useEffect, useState } from 'preact/hooks';

const Product = (props) => {

	const [picked, setPicked] = useState(props.picked);

	return(
		<div class="product">
			<span class="item-name">{props.item.product.name}</span>
			{/*<div class="item-picture">img</div>*/}
			<img class="item-picture" src={props.item.product.images[0]} />
			<div class="product-footer">
				<span class="price-label">{"$" + props.item.product.metadata.price}</span>
				{
					(() => {
						if (!picked) {
							return(<button onClick={()=>{
								setPicked(true);
								props.addAction(props.item);
							}}>Add to Cart</button>);
						}
						else {
							return("Added!");
						}
					})()
				}
			</div>
		</div>
	);
}
export default Product;
