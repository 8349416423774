import './style';
import { h, Fragment, Component } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Header from './header';
import Products from './products';
import Checkout from './checkout';
import Music from './music';

import Router from 'preact-router';
import Thanks from './thanks';

import { createBrowserHistory } from 'history';

const App = () => {

	const [cartItems, setCartItems] = useState([]);

	const addItemToCart = (item) => {
		setCartItems(cartItems.concat(item));
	}

	const removeFromCart = (price) => {
		setCartItems(
			cartItems.filter(x => (x.price != price))
		);
	}

	const history = createBrowserHistory();
	const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
	if (path) {
		history.replace(path);
	}

	return (
		<div class="container">
			<Header cartItems={cartItems} />
			<Router >
				<div path="/" class="content-main squat">
					Welcome to my site
				</div>
				<div path="/shop" class="content-main stand">
					<Products cartItems={cartItems} addToCart={addItemToCart} />
				</div>
				<div path="/music" class="content-main floor">
					<Music />
				</div>
				<div path="/about" class="content-main jacket">
					I'm radley bitch
				</div>
				<div class="content-main" path="/checkout">
					<Checkout remove={removeFromCart} cart={cartItems}  />
				</div>
				<Thanks path="/thanks" />
			</Router>
			<div class="footer">
				<strong>Copyright Damnaged Goods 2020</strong>
				<a href="#">Customer Service</a>
				<a href="#">Customer Service</a>
				<a href="#">Our Company</a>
				<a href="#">Legal & Cookies</a>
			</div>
		</div>
	);
}

export default App;
