import { h, Fragment, Component } from 'preact';
import {useRef , useState, useEffect} from 'preact/hooks';
import CheckoutItem from './checkoutitem';
import {loadStripe} from '@stripe/stripe-js';

const Checkout = (props) => {

	const [checkoutWith, setCheckoutWith] = useState([]);
	const stripe = useRef(null);

	useEffect(async () => {
		stripe.current = await loadStripe("pk_test_hT47WS7TC1YKGFGbF2mJ4En100a4INSKeG");
		setCheckoutWith(props.cart.map(x => (
			{'price':x.price, 'quantity':1}	
		)));
	}, []);

	const changeQuantity = (price, quantity) => {
		let newCheckout = checkoutWith.map((row) => {
			if (row['price'] == price) {
				return({'price':price, 'quantity':parseInt(quantity)});
			}
			else {
				return({'price':row['price'], 'quantity':parseInt(row['quantity'])});
			}
		});
		setCheckoutWith(newCheckout);
	}

	const remove = (price) => {
		setCheckoutWith(
			checkoutWith.filter(x => (x['price'] != price))
		);
		props.remove(price);
	}

	return (
		<Fragment>
			{
				(() => {
					if (props.cart.length > 0) {
						return (
							<Fragment>
							<div class="checkout-items">
							{
								(() => {
									return props.cart.map(x => (
										<CheckoutItem remove={remove} change={changeQuantity} item={x} />
									));
								})()
							}
							</div>
							<button class="checkout-button" onClick={
								() => {
									console.log("Checking out");
									console.log(checkoutWith);
									try {
										stripe.current.redirectToCheckout({
											lineItems: checkoutWith, 
											mode: "payment",
											successUrl: "http://www.damnagedgoods.com.s3-website-us-east-1.amazonaws.com/thanks",
											cancelUrl: "http://www.damnagedgoods.com.s3-website-us-east-1.amazonaws.com/checkout",
											shippingAddressCollection: {
												allowedCountries: ['US', 'CA'],
											}
										})
										.then(res => console.log);
									}
									catch (err) {
										console.log("Stripe error");
										console.log(err);
									}
								}
							}>Check Out</button>
							</Fragment>
						);
					}
					else {
						return("Add some items to ur cart");
					}
				})()
			}
		</Fragment>
	);
}

export default Checkout;
