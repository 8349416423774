import { h, Fragment, Component } from 'preact';
import { useState, useRef } from 'preact/hooks';

const CheckoutItem = (props) => {

	const [quantity, setQuantity] = useState(1);
	const qref = useRef(null);

	return (
		<div class="checkout-item">
			{/*<div class="checkout-img">img</div>*/}
			<img src={props.item.product.images[0]} class="checkout-img" />
			<div class="placeholder">
				<span>{props.item.product.name}</span>
				<span>{"$" + props.item.product.metadata.price}</span>
			</div>
			<div>
				<label>Quantity: </label>
				<input onChange={
					() => {
						setQuantity(qref.current.value);
						props.change(props.item.price, qref.current.value);
					}
				} ref={qref} min={1} type="number" value={quantity} />
				<button onClick={() => {
					props.remove(props.item.price);
				}}>remove</button>
			</div>
		</div>
	);
}

export default CheckoutItem;
